
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {ContaCorrenteService,BancoService} from "@/core/services/financeiro";
import { ContaCorrente, Banco } from "@/core/models/financeiro";
import { EmpresaService } from "../../../core/services/compras/EmpresaService";
import { Empresa } from "@/core/models/cadastros";
import * as jsonpatch from 'fast-json-patch';
import CertificadoBanco from "@/core/models/financeiro/CertificadoBanco";
import {formatDate, TratarErroApi} from '@/assets/scripts/helper'

@Component
export default class CadastroContaCorrente extends Vue {
  @Prop() public item!: ContaCorrente;
  @Prop() public value!: string;

  service = new ContaCorrenteService();
  listaBanco: Banco[] = [];
  listaEmpresa: Empresa[] = [];
  ConteudoNome:string = ''

  formatDate = formatDate

  valid = true;
  dialog = false;
  //abrirAceiteTermos = false;
  arquivo = null;  
  tabActive = 0;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  novoCertificado = new CertificadoBanco;
  file: any = null;
   
  observer! : jsonpatch.Observer<ContaCorrente>; 

    convertToBase64() {
      if (!this.file) {
        console.warn("Nenhum arquivo foi selecionado.");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        console.log("Leitura concluída. Conteúdo base64: ", reader.result);

        // Atualiza o nome do arquivo
        this.ConteudoNome = this.file.name;

        // Armazena o conteúdo em Base64 (sem a parte do prefixo data)
        this.novoCertificado.conteudo = (reader.result as string).replace(/^data:.*;base64,/, "");
        console.log("Conteúdo Base64:", this.novoCertificado.conteudo);
      };

      reader.onerror = (error) => {
        console.error("Erro ao ler o arquivo:", error);
      };

      // Lê o arquivo como uma URL de dados (Base64)
      reader.readAsDataURL(this.file); // Aqui `this.file` é o arquivo selecionado
    }
  
  
  

//   convertToBase64(file: File | null) {
//   if (!file) { 
//     console.warn("Nenhum arquivo foi selecionado.");
//     return;
//   }
  
//   const reader = new FileReader();
//   reader.onload = () => {
//     this.ConteudoNome = file.name;
//     this.novoCertificado.conteudo = (reader.result as string).replace(/^data:.*;base64,/, ""); // Armazena o conteúdo em Base64
//   };
//   reader.onerror = (error) => {
//     console.error("Erro ao ler o arquivo:", error);
//   };

//   reader.readAsDataURL(file);
// }


  adicionarCertificado() {
      (this.service.RegistrarCertificado(this.item.id, this.novoCertificado)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Atualizar()
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
    this.resetForm();
  }

  resetForm() {
    this.novoCertificado = new CertificadoBanco;
    (this.$refs.form as any).reset();
  }

  removerCertificado(index) {
    const certificado = this.item.certificados[index];
    (this.service.RemoverCertificado(this.item.id, certificado.id)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.item.certificados!.splice(index, 1); 
        this.Atualizar()
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }
 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Atualizar(){
     this.service.ObterPorId(this.item.id, "Certificados").then((res) => {this.item.certificados = res.data.certificados;});
  }
 
  mounted() {
    let bancoService = new BancoService();
    bancoService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'id, nome', '').then(
      (res) => {this.listaBanco = res.data.items},
    );

    let empresaService = new EmpresaService();
    empresaService.Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      (res) => {this.listaEmpresa = res.data.items;},
    );
  }

  SalvarCertificado() { 
    if (this.$refs.form.validate()) { 
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.service.Patch(pacthModel, this.item.id)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Atualizar()
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
    }
  }

  Salvar() { 
    if (this.$refs.form.validate()) { 
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
    }
  }

  CarregarDadosArquivo(){
    if(!this.arquivo){
      return;
    }

    this.service.UploadCertificadoDigital(this.item.id, this.arquivo).then(
      (res) => {
        this.$emit("salvou")
        },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
  }

  Close() {
    this.dialog = false; 
    this.arquivo = null
  }
}
