
import { Contrato, ContratoDigital, SignatarioContratoDigital } from "@/core/models/cadastros";
import { ContratoService } from "@/core/services/cadastros";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VCardObservacao extends Vue {
  @Prop() lista?: any[];
  @Prop() item!: Contrato;

  contrato = new Contrato();
  listaObservacoes = [];   
  singleExpand: boolean = false;
  expand: boolean = false;
  expanded = [];
  service = new ContratoService();
  contratoDigital = new ContratoDigital();
  dialogCadastroDigital = false;

   getColor(item) {
    if (item.situacaoId == 1) 
      return "#00B0FF";

    else if (item.situacaoId == 2) 
      return "#EEFF41";

    else if (item.situacaoId == 3) 
      return "#00B8D4";

    else if (item.situacaoId == 4) 
      return "#4CAF50";

    else if (item.situacaoId == 5) 
      return "#BDBDBD";

    else if (item.situacaoId == 6) 
      return "#455A64";

    else if (item.situacaoId == 7) 
      return "##FFAB91";

    else return "#8D6E63";
  }
  
  dialogCadastroSignatarios = false;
  overlay2 = false;
  loading = false;

  headers = [
    { text: "Data", value: "data" },
    { text: "Documento", value: "documento" },
    { text: "Situação", value: "situacao" },
    { text: "Ações", value: "actions", align: "right" },
  ]

  ContratoDigital(item){
    this.overlay2 = !this.overlay2;
    
    setTimeout(()=> {
      this.service.ObterPorId(this.item.id, 'ContratosDigitais.Signatarios.Cliente, Corretores.Corretor, Clientes.Cliente, Empreendimento.Proprietarios.Proprietario').then(
        res => { 
          this.contratoDigital = new ContratoDigital(item);

          this.contratoDigital.signatarios.forEach(x => {
            if(x.cliente.juridicaResponsavel.endereco.municipioId == 0)
              x.cliente.juridicaResponsavel.endereco.municipioId = 1303502;
            }
          )
          this.contrato = res.data; 
          this.dialogCadastroDigital = true;
          this.overlay2 = false;
          this.$emit("salvou");
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }
      )
    },1500) 
  }
 
  ExcluirModelo(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir Contrato digital?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.ExcluirContratoDigital(item.id).then(
          (res) => {
            if (res.status == 200) {
             this.AtualizarContrato();
              return res;
            }
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
            return err;
          }); 
          
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
      
          this.$swal("Aviso", result.value.status == 200 ? result.value.data : result.value.response.data, result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  AdicionarSignatarios(item){ 
    this.contratoDigital = new ContratoDigital(item);
    this.dialogCadastroSignatarios = true;
  }

  UploadDocumento(item){
    this.service.SalvarDocumento(item.id);

    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja fazer o Upload do Contrato Digital?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.UploadDocumentoDfourSign(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.AtualizarContrato();
              return res;
            }
          },
          (err) => { 
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
            return err;
          }); 
          
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
      
          this.$swal("Aviso", result.value.status == 200 ? result.value.data : result.value.response.data, result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  EnviarAssinatura(item){
    const modelSignatarios = {signatariosIds:item.signatarios.map(signatario => signatario.id), dFourSignDocumentId: item.dFourSignDocumentId};

    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja enviar para a Assinatura?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CadastrarSignatariosD4Sign(modelSignatarios).then(
          (res) => {
            if (res.status == 200) {
              this.service.EnviarDocumentoAssinaturaD4Sign(item.id).then(
                res => {
                  this.$swal("Aviso", res.status == 200 ? res.data : res.data, res.status == 200 ? "success" : "warning")
                  this.AtualizarContrato();
                },
                (err) => { 
                  if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                  } else if (err.response.status == 403) {
                    this.$swal("Aviso", err.response.data.message, "warning" );
                  } else {
                    this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
                  }
                  return err;
                }
              )
              return res;
            }
          },
          (err) => { 
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
            return err;
          }); 
          
      },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    })
    .then((result: any) => {
      if (result.value) {
          this.$swal("Aviso", result.value.response.data, "warning"
        );
      }
    });
  }
  
  CancelarContrato(item){
    console.log(item)
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja Cancelar o Documento?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarContratoDigital(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.AtualizarContrato();
              return res;
            }
          },
          (err) => { 
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
            return err;
          }); 
          
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
      
          this.$swal("Aviso", result.value.status == 200 ? result.value.data : result.value.response.data, result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  } 

  DownloadContrato(item) {
  this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja fazer o Download do Contrato Digital?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return this.service.DownloadContratoDigital(item.dFourSignDocumentId).then(
        (res) => {
          if (res.status == 200) {
            const responseData = res.data; // Base64 ou URL no retorno.
            const { url, name } = JSON.parse(atob(responseData)); // Decodifica o JSON Base64
            if (url) {
              // Cria um link para o download
              const link = document.createElement("a");
              link.href = url; // URL do arquivo
              link.download = name || "ContratoDigital.pdf"; // Nome sugerido
              //link.target = "_blank"; // Para abrir em nova aba (opcional)
              link.click(); // Aciona o clique para download
            }
            return res;
          }
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal(
              "Aviso",
              err.response.data,
              err.response.status == 400 ? "warning" : "error"
            );
          }
          return err;
        }
      );
    },
    allowOutsideClick: () => !this.$swal.isLoading(),
  }).then((result) => {
    if (result.value) {
      this.$swal(
        "Aviso",
        result.value.status == 200
          ? "Download iniciado com sucesso."
          : result.value.response.data,
        result.value.status == 200 ? "success" : "warning"
      );
    }
  });
  }
 

  @Watch("lista")
  ObservadorLista(novoValor: any) {
    this.listaObservacoes = Object.assign([], this.lista);
  } 
 
  AtualizarContrato(){
    this.loading = true;

    this.service.ObterPorId(this.item.id, "ContratosDigitais.Signatarios.Cliente, ContratosDigitais.Signatarios.Situacao,ContratosDigitais.Tipo,ContratosDigitais.Situacao, TipoAmortizacao, Receitas.Parcelas.AntecipacaoComprovante, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Baixas.FormaPagamento,Receitas.Tipo, Receitas.Parcelas.Baixas.ReceitaBaixaComprovante, TipoIndice,  HistoricoSituacoes.Situacao, HistoricoSituacoes.Usuario")
      .then((res) => {
        this.listaObservacoes = res.data.contratosDigitais;
        this.loading = false;
      }); 
  } 

  mounted() {
    this.listaObservacoes = Object.assign([], this.lista);
  }
}
