
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContaCorrenteService, ParametroFinanceiroService, ReceitaService } from "@/core/services/financeiro";
import { Encargo, ReceitaParcela } from "@/core/models/financeiro";
import { TipoOperacaoFinanceiraService } from "@/core/services/contratos";

@Component
export default class EditarParcelaManual extends Vue { 
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;

  receitaService = new ReceitaService();
  valid = true;
  dialog = false;
   
  aplicarJuros:boolean = false;

  listaGateway:any =[];
  tiposOperacaoContrato = [];
  listaContaCorrentes:any = [];

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  }; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.ObterParametroPorId(this.item.receita!.empresaId)
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 
  }

  ObterParametroPorId(id){
    new ParametroFinanceiroService().ObterParametroEmpresaPorId(id, 'Gatways.Tipo').then(
      res => { 
        this.listaGateway = res.data.gatways;
      }
    )
  }

  ObterParametroGatway(item){
    if(item){
      this.item.encargoFinanceiro = item.encargoFinanceiro;
      this.item.tipoGatewayId = item.tipoId;
    }else{
      this.item.encargoFinanceiro = new Encargo();
      this.item.tipoGatewayId = 0;
    } 
  }

  get titulo(){
    if(!!this.item.urlBoleto || this.item.situacaoId == 2 || this.item.situacaoId == 3 || this.item.situacaoId == 4 || this.item.agrupadorId > 0){
     return "Visualizar"
    }else{
      return "Editar"
    }
  }
   
  Salvar() { 
    if (this.$refs.form.validate()) {

    let modelo = this.item;
    this.receitaService.EditarParcela(modelo.id, modelo, this.aplicarJuros).then(
    (res) => {
      this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
      this.$emit("salvou");
      this.Close();
    },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
    }
  }

  Close(){
    this.aplicarJuros = false;
    this.dialog = false;
  }

  get codigoBanco() {
    const contaCorrente = this.listaContaCorrentes.find((x: any) => this.item.contaCorrenteId == x.id);
    return contaCorrente ? contaCorrente.banco.codigo : '';
  }
 
  mounted(){
    const tipoOperacaoContrato = new TipoOperacaoFinanceiraService();
    tipoOperacaoContrato.ListarTudo().then( 
      (res) => {
        this.tiposOperacaoContrato = res.data.items;
      }
    )

  const contaCorrenteService = new ContaCorrenteService();
    contaCorrenteService.Listar(-1, -1, ['id'],[],'',[], '', '' , '', 'Banco').then(
      (res) => {
        this.listaContaCorrentes = res.data.items;
      }
    )
  }
}
